<template>
  <v-card class="mt-1">
    <v-toolbar dark color="primary">
      <div class="text-h6">Check-Overtimes</div>

      <v-divider class="mx-2" vertical />
      <v-autocomplete
        class="mx-1"
        label="Filter by Employees"
        :items="workers"
        v-model="bodyreq.employeeUuid"
        item-text="fullname"
        item-value="uuid"
        style="max-width: 300px"
        prepend-inner-icon="mdi-account-search"
        clearable
        @change="filterByDay"
        @click:clear="resetFilter('employees')"
        hide-details
        outlined
        dense
      >
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item.fullname"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.rol"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-html="data.item.phone"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <v-layout class="ma-4">
        <v-row class="mt-3" no-gutters>
          <v-col class="mt-3">
            <ma-date-picker v-model="dateFrom" label="From" past />
          </v-col>
          <v-col class="ml-2 mt-3">
            <ma-date-picker v-model="dateTo" label="To" />
          </v-col>
        </v-row>
      </v-layout>
      <template v-if="validClean">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="ml-2"
              elevation="0"
              small
              rounded
              depressed
              fab
              v-on="on"
              v-bind="attrs"
              @click="resetFilter('custom-select')"
            >
              <v-icon>mdi-backup-restore</v-icon>
            </v-btn>
          </template>
          <span>Clear Dates</span>
        </v-tooltip>
      </template>
    </v-toolbar>
    {{/* Interval indicator */}}
    <v-layout class="pa-4" column>
      <div class="mr-2 text-capitalize">
        {{ filterRanges.replace("-", " ") }}
      </div>
      <v-layout column class="text-body-1 text--secondary">
        <div>
          From:
          <span class="text-caption">{{ prettyDate(bodyreq.dates[0]) }}</span>
        </div>
        <div>
          To:
          <span class="text-caption">{{ prettyDate(bodyreq.dates[1]) }}</span>
        </div>
      </v-layout>
    </v-layout>

    <div class="d-flex justify-end">
      <template v-if="isSuper || isAdmin">
        <v-dialog
          v-model="dialog"
          scrollable
          persistent
          :overlay="true"
          max-width="600px"
          transition="dialog-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" class="my-1" rounded color="primary"
              ><v-icon>mdi-plus</v-icon>OverTime</v-btn
            >
          </template>
          <v-card>
            <v-toolbar dark color="primary"> Add Overtime </v-toolbar>
            <v-card-text>
              <div class="d-flex mt-2">
                <div class="col-sm-6">
                  <v-autocomplete
                    :items="workers"
                    item-text="fullname"
                    item-value="uuid"
                    outlined
                    dense
                    v-model="req.employeeUuid"
                    label="Employee"
                  ></v-autocomplete>
                </div>
              </div>
              <div class="d-flex mt-2">
                <div class="col-sm-6">
                  <v-text-field
                    outlined
                    v-model="hours"
                    type="number"
                    :rules="[rules.required]"
                    @click="clickHours"
                    dense
                    label="Hours"
                  ></v-text-field>

                  <template v-if="noValidHour">
                    <span class="red--text">
                      {{ "The time must be between " + 0 + " and " + 24 }}
                    </span>
                  </template>
                </div>
                <div class="col-sm-6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="dates"
                        multiple
                        chips
                        small-chips
                        label="Days"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-combobox>
                    </template>
                    <v-date-picker v-model="dates" no-title multiple scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
              <template>
                <div class="d-flex justify-end">
                  <v-btn
                    @click="addExtras"
                    :disabled="_inValidForm"
                    color="primary"
                    ><v-icon>mdi-plus</v-icon> Add</v-btn
                  >
                </div>
              </template>

              <template v-if="req.extras.length != 0">
                <v-divider></v-divider>
                <div class="d-flex">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Day</th>
                          <th class="text-left">Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(h, index) in req.extras" :key="index">
                          <td>{{ prettyDay(h.day) }}</td>
                          <td>{{ h.hours + "H" }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </template>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="cancel"> Cancel </v-btn>
              <v-btn
                :disabled="invalidAdd"
                color="primary"
                text
                @click="addovertime"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </div>

    <v-divider />
    <v-data-table
      :options.sync="options"
      :server-items-length="totalovertimes"
      item-key="uuid"
      :loading="loading"
      :headers="headers"
      :items="overtimes"
      :footer-props="footerProps"
      class="elevation-1"
    >
      <template v-slot:[`item.employee`]="{ item }">
        {{ item.employee.fullname }}
      </template>

      <template v-slot:[`item.day`]="{ item }">
        {{ prettyDateSH(item.day) }}
      </template>

      <template v-slot:[`item.hours`]="{ item }">
        {{ item.hours + "h" }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ prettyDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <btn-add-hours
          :employee="item.employee"
          :day="item.day"
          @overtimeadded="filterByDay"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";
import * as mont from "moment-timezone";
import { notifyError, notifySuccess } from "@/components/Notification";
import rules from "@/components/account/rules";
import { ResponseStatus } from "@/models/ResponseStatusEnum";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import BtnAddHours from "@/views/Employees/buttons/BtnAddHours.vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  components: { MaDatePicker, BtnAddHours },
  data: () => ({
    dialog: false,
    menu: false,
    rules,
    hours: 0,
    dates: [],
    req: {
      employeeUuid: "",
      extras: [],
    },

    filterRanges: "today",
    filterEmployees: "",
    dateFrom: "",
    dateTo: "",
    headers: [
      {
        text: "Employee",
        align: "start",
        sortable: false,
        value: "employee",
      },
      {
        text: "Day",
        value: "day",
        sortable: false,
      },
      {
        text: "Hours",
        value: "hours",
        sortable: false,
      },
      {
        text: "Create Date",
        value: "createdAt",
        sortable: false,
      },

      { text: "", value: "actions" },
    ],

    intervals: [
      { label: "Today", value: "today" },
      { label: "Yesterday", value: "yesterday" },
      { label: "This week", value: "this-week" },
      { label: "Last week", value: "last-week" },
      { label: "This month", value: "this-month" },
      { label: "Last month", value: "last-month" },
      { label: "This year", value: "this-year" },
      { label: "Last year", value: "last-year" },
    ],
    options: {},
    footerProps: { "items-per-page-options": [50, 100, 500] },
    bodyreq: {
      employeeUuid: null,
      range: {
        limit: 100,
        offset: 0,
      },
      dates: ["", ""],
    },
  }),
  watch: {
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.filterRanges = "custom-select";
        this.filterByDay();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.filterRanges = "custom-select";
        this.filterByDay();
      }
    },
  },
  computed: {
    ...mapState("crmConfigurationsModule", [
      "overtimes",
      "loading",
      "totalovertimes",
    ]),
    ...mapGetters(["isSuper", "isAdmin", "isSupervisor"]),
    ...mapState("crmEmployeeModule", ["workers"]),
    validClean() {
      if (
        (this.dateFrom != null &&
          this.dateFrom != undefined &&
          this.dateFrom != "") ||
        (this.dateTo != null && this.dateTo != undefined && this.dateTo != "")
      ) {
        return true;
      }
      return false;
    },
    _inValidForm() {
      if (this.hours == 0 || this.dates.length == 0) {
        return true;
      }
      return false;
    },
    noValidHour() {
      if (Number(this.hours) < 0 || Number(this.hours) > 24) {
        return true;
      }
      return false;
    },
    invalidAdd() {
      if (this.req.employeeUuid == "" || this.req.extras == []) {
        return true;
      }
      return false;
    },

    /*INTERVALS*/
    intervalToday() {
      const from = moment().startOf("day").utc().toISOString();
      const to = moment().endOf("day").utc().toISOString();

      return {
        date1: from,
        date2: to,
      };
    },
  },
  methods: {
    ...mapActions("crmConfigurationsModule", [
      "actFilterOvertimes",
      "actAddOvertime",
    ]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    addExtras() {
      this.dates.forEach((d) => {
        this.req.extras.push({
          day: moment(d).toISOString(),
          hours: Number(this.hours),
        });
      });
      this.dates = [];
      this.hours = 0;
    },

    addovertime: function () {
      this.actAddOvertime(this.req)
        .then(() => {
          this.filterByDay();
          notifySuccess("OverTime added  successfully");

          this.cancel();
        })
        .catch((error) => {
          const errorResponse = error.response?.status;
          let message =
            error.response?.data.message || error.response?.data.details;

          message = error.response?.data.response.message
            ? message + ", " + error.response?.data.response.message[0]
            : message;

          if (errorResponse == ResponseStatus.BAD_REQUEST) {
            const messages =
              message instanceof Array
                ? message.reduce(
                    (fullMessage, message) => fullMessage + "\n" + message
                  )
                : message;

            notifyError(messages);
            this.cancel();
          }
        });
    },

    cancel() {
      this.hours = 0;
      this.dates = [];
      this.menu = false;
      this.dialog = false;
      this.req = { employeeUuid: "", extras: [] };
    },

    prettyMinutes(minutes) {
      const mins = Number(minutes);

      if (mins == 0) {
        return "00h:00m";
      }
      let hour = Math.floor(minutes / 60);
      hour = hour < 10 ? "0" + hour : hour;
      var minute = parseInt(minutes % 60);

      minute = minute < 10 ? "0" + minute : minute;
      return hour + "h" + ":" + minute + "m";
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      return mont(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },

    prettyDateH(date) {
      if (date === null || date === "") return "";
      const fech = mont(date)
        .tz("America/New_York")
        .format("dddd D MMMM YYYY, h:mm:ss a");
      return fech.split(",")[1];
    },
    prettyDateSH(date) {
      if (date === null || date === "") return "";
      const fech = mont(date)
        .tz("America/New_York")
        .format("dddd D MMMM YYYY, h:mm:ss a");
      return fech.split(",")[0];
    },
    prettyDay(date) {
      if (date === null || date === "") return "";

      const fecha = moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
      const day = fecha.split(" ")[0] + " " + fecha.split(" ")[1];

      return day;
    },

    prettyDetails(details) {
      if (details.length <= 20) {
        return details;
      } else {
        return details.substring(0, 20) + "...";
      }
    },

    async setIntervar(interval) {
      this.filterRanges = interval;
      await this.filterByDay();
    },

    async filterByDay() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 50;

      let body = {
        dateDange: this.bodyreq.dates,
        employeeUuid: this.bodyreq.employeeUuid,
        range: {
          limit: itmper,
          offset: (page - 1) * itmper,
        },
      };
      switch (this.filterRanges) {
        case "today":
          body.dateDange = [this.intervalToday.date1, this.intervalToday.date2];
          break;

        case "custom-select":
          body.dateDange = [
            moment(this.dateFrom).startOf("day").toISOString(),
            moment(this.dateTo).endOf("day").toISOString(),
          ];
          break;
      }
      this.bodyreq.dates = body.dateDange;
      await this.actFilterOvertimes(body);
    },

    resetFilter(value) {
      switch (value) {
        case "custom-select":
          this.filterRanges = "today";
          this.dateFrom = "";
          this.dateTo = "";

          this.filterByDay();
          break;
        case "employees":
          this.filterEmployees = "";
          break;
        default:
          this.filterEmployees = "";

          this.filterRanges = "today";
          this.bodyreq = {
            employeeUuid: null,
            dates: ["", ""],
          };
          this.filterByDay();
          break;
      }
    },
    clickHours() {
      if (this.hours == 0) {
        this.hours = "";
      }
    },
  },
  async mounted() {
    this.actGetEmployees();
    await this.setIntervar(this.filterRanges);
  },
};
</script>
<style lang=""></style>
