





































import Vue from "vue";
import { mapGetters } from "vuex";
import ClockCheck from "./Clock-check.vue";
import ClockLog from "./ClockLog.vue";
import CheckOvertimes from "./Check-Overtimes.vue";

export default Vue.extend({
  components: { ClockCheck, ClockLog, CheckOvertimes },
  name: "clock-in-check",
  data() {
    return {
      tabSelect: null,
    };
  },
  computed: {
    ...mapGetters(["isSuper", "isAdmin"]),
  },
});
