<template>
  <v-card class="mb-4 mt-1 overflow-hidden" :disabled="loading">
    <v-toolbar class="primary" tag="div" flat dark tile>
      <div class="text-h6">Clock Logs</div>
      <v-divider class="mx-2" vertical />
      <v-autocomplete
        class="mx-1"
        label="Filter by Status"
        :items="items"
        v-model="bodyreq.status"
        item-text="name"
        item-value="value"
        style="max-width: 300px"
        clearable
        @change="filterClock"
        @click:clear="resetFilter('status')"
        hide-details
        outlined
        dense
      >
      </v-autocomplete>
      <v-autocomplete
        class="mx-1"
        label="Filter by Employees"
        :items="workers"
        v-model="bodyreq.employeeUuid"
        item-text="fullname"
        item-value="uuid"
        style="max-width: 300px"
        prepend-inner-icon="mdi-account-search"
        clearable
        @change="filterClock"
        @click:clear="resetFilter('employees')"
        hide-details
        outlined
        dense
      >
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item.fullname"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.rol"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-html="data.item.phone"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>

      <v-layout class="ma-4">
        <v-row class="mt-3" no-gutters>
          <v-col class="mt-3">
            <ma-date-picker v-model="dateFrom" label="From" past />
          </v-col>
          <v-col class="ml-2 mt-3">
            <ma-date-picker v-model="dateTo" label="To" past />
          </v-col>
        </v-row>
      </v-layout>
      <template v-if="validClean">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="ml-2"
              elevation="0"
              small
              rounded
              depressed
              fab
              v-on="on"
              v-bind="attrs"
              @click="resetFilter('custom-select')"
            >
              <v-icon>mdi-backup-restore</v-icon>
            </v-btn>
          </template>
          <span>Clear Dates</span>
        </v-tooltip>
      </template>
    </v-toolbar>
    {{/* Interval indicator */}}
    <v-layout class="pa-4" column>
      <div class="mr-2 text-capitalize">
        {{ filterRanges.replace("-", " ") }}
      </div>
      <v-layout column class="text-body-1 text--secondary">
        <div>
          From:
          <span class="text-caption">{{
            prettyDateShow(bodyreq.dates[0])
          }}</span>
        </div>
        <div>
          To:
          <span class="text-caption">{{
            prettyDateShowTo(bodyreq.dates[1])
          }}</span>
        </div>
      </v-layout>
    </v-layout>
    <v-data-table
      :headers="headers"
      :options.sync="options"
      :server-items-length="totalitems"
      single-expand
      show-expand
      :expanded.sync="expanded"
      :items="clockins"
      :footer-props="footerProps"
      :loading="loading"
      loading-text="Loading... Please wait"
      item-key="uuid"
    >
      <template v-slot:[`item.createdBy`]="{ item }">
        {{ item.createdBy.fullname }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span
          :class="
            item.status == 'CLOKING'
              ? 'green--text'
              : item.status == 'HOLD'
              ? 'orange--text'
              : 'red--text'
          "
          >{{
            item.status == "CLOKING"
              ? "ACTIVE"
              : item.status == "HOLD"
              ? "HOLD"
              : "CLOCKOUT"
          }}</span
        >
      </template>
      <template v-slot:[`item.minutes`]="{ item }">
        {{ prettyMinutes(item.minutes, item) }}
      </template>
      <template v-slot:[`item.startDate`]="{ item }">
        {{ prettyDate(item.startDate) }}
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        {{ item.endDate ? prettyDate(item.endDate) : "-" }}
      </template>
      <template v-slot:[`item.details`]="{ item }">
        {{
          item.status == "CLOCKOUT"
            ? 0
            : calculate(item.minutes, item.createdBy.payXhour, item.status)
              | currency
        }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="d-flex ma-4">
            <v-layout>
              <v-row no-gutters>
                <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                  <div class="body-1">
                    <v-icon class="mr-2">mdi-account</v-icon>
                    <strong>{{ item.createdBy.fullname }}</strong>
                  </div>
                  <!--  <div class="body-2">
                    <strong>Start: </strong>
                    {{ prettyDate(item.startDate) }}
                  </div> -->
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                  <div class="body-1">
                    <strong>Status: </strong>
                    <span
                      :class="
                        item.status == 'CLOKING' ? 'green--text' : 'red--text'
                      "
                      >{{
                        item.status == "CLOKING" ? "CLOCK-IN" : "CLOCK-OUT"
                      }}</span
                    >
                  </div>
                  <!--   <div class="body-2">
                    <strong>End: </strong>
                    {{ item.endDate == null ? "-" : prettyDate(item.endDate) }}
                  </div> -->
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                  <div class="body-2">
                    <strong> Time: </strong>
                    {{ prettyMinutes(item.minutes, item) }}
                  </div>
                  <div class="body-2">
                    <template v-if="item.status != 'CLOKING'">
                      <strong> Details:</strong>
                      {{ item.details }}
                    </template>
                  </div>
                </v-col>
                <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                  <div class="my-2">
                    <strong
                      >Image:
                      <template v-if="imageSrc == 'no-image'">
                        No Image
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            imageSrc == undefined ||
                            imageSrc == '' ||
                            loadingImg
                          "
                        >
                          <v-progress-circular
                            indeterminate
                            color="error"
                          ></v-progress-circular>
                        </template>
                      </template>
                    </strong>
                    <template
                      v-if="
                        imageSrc != undefined &&
                        imageSrc != '' &&
                        imageSrc != 'no-image'
                      "
                    >
                      <template v-if="!loadingImg">
                        <v-img
                          max-height="250"
                          max-width="250"
                          :src="imageSrc"
                          contain
                          aspect-ratio="1"
                        >
                        </v-img>
                      </template>
                    </template>
                  </div>
                </v-col>
              </v-row>
            </v-layout>
          </div>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import * as mont from "moment-timezone";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";
import store from "@/store";

export default Vue.extend({
  components: { MaDatePicker },
  name: "clock-logs",
  data() {
    return {
      footerProps: { "items-per-page-options": [15, 25, 50, 100, 500] },
      dateFrom: "",
      tabSelect: null,
      options: {},
      expanded: [],
      loadingImg: false,
      dateTo: "",
      filterRanges: "today",
      filterEmployees: "",
      filterStatus: "",
      headers: [
        { text: "Employee", value: "createdBy", sortable: false },
        { text: "Status", value: "status", sortable: false, align: "center" },
        { text: "Time", value: "minutes", sortable: false },
        { text: "Start Date", value: "startDate", sortable: false },
        { text: "End Date", value: "endDate", sortable: false },
        { text: "", value: "data-table-expand", sortable: false },
      ],
      intervals: [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This week", value: "this-week" },
        { label: "Last week", value: "last-week" },
        { label: "This month", value: "this-month" },
        { label: "Last month", value: "last-month" },
        { label: "This year", value: "this-year" },
        { label: "Last year", value: "last-year" },
      ],
      items: [
        { name: "CLOCK-IN", value: "CLOKING" },
        { name: "CLOCK-OUT", value: "CLOCKOUT" },
      ],
      bodyreq: {
        employeeUuid: null,
        status: "",
        dates: ["", ""],
        range: {
          limit: 15,
          offset: 0,
        },
      },
    };
  },
  computed: {
    ...mapState("crmEmployeeModule", ["workers"]),
    ...mapState("crmConfigurationsModule", [
      "clockins",
      "loading",
      "amountSelet",
      "imageSrc",
    ]),
    ...mapState(["totalitems"]),
    ...mapGetters(["isSuper"]),
    validClean() {
      if (
        (this.dateFrom != null &&
          this.dateFrom != undefined &&
          this.dateFrom != "") ||
        (this.dateTo != null && this.dateTo != undefined && this.dateTo != "")
      ) {
        return true;
      }
      return false;
    },
    employeSelect() {
      const employe = this.workers.filter(
        (w) => w.uuid == this.bodyreq.employeeUuid
      );
      if (employe != null && employe != undefined) {
        return employe[0];
      }
      return null;
    },

    /*INTERVALS*/
    intervalToday() {
      const from = moment().startOf("day").utc().toISOString();
      const to = moment().utc().toISOString();

      return {
        date1: from,
        date2: to,
      };
    },
  },
  watch: {
    expanded(val) {
      this.mutImagenSrc("");
      if (val != null && val != [] && val != undefined && val.length != 0) {
        this.srcimg(val[0].photo);
      }
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.filterRanges = "custom-select";
        this.filterClock();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.filterRanges = "custom-select";
        this.filterClock();
      }
    },
    options(val) {
      if (val != {}) {
        this.filterClock();
      }
    },
  },
  methods: {
    ...mapActions("crmConfigurationsModule", ["actFilterClock"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapMutations("crmConfigurationsModule", ["mutImagenSrc"]),
    ...mapMutations(["mutReqRange"]),
    async setIntervar(interval) {
      this.filterRanges = interval;
      await this.filterClock();
    },

    prettyDateShow(date) {
      if (date === null || date === "") return "";
      /*   if (this.filterRanges == "custom-select") {
        return moment(date)
          .utc()
          .tz("America/New_York")
          .local()
          .format("dddd D MMMM YYYY, h:mm:ss a");
      } */
      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    prettyDateShowTo(date) {
      if (date === null || date === "") return "";
      /* if (this.filterRanges == "custom-select") {
        return moment(date)
          .utc()
          .tz("America/New_York")
          .local()
          .format("dddd D MMMM YYYY, h:mm:ss a");
      } */
      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },

    async filterClock() {
      this.expanded = [];
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 15;

      let body = this.bodyreq;
      body.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      this.mutReqRange(body.range);
      switch (this.filterRanges) {
        case "today":
          body.dates = [this.intervalToday.date1, this.intervalToday.date2];
          break;
        case "custom-select":
          body.dates = [
            moment(this.dateFrom).startOf("day").utc().toISOString(),
            moment(this.dateTo).endOf("day").utc().toISOString(),
          ];
          break;
      }
      body = this.cleanNull(body);
      this.expanded = [];
      await this.actFilterClock(body);
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },

    prettyMinutes(minutes, item) {
      const mins = Number(minutes);

      if (mins == 0) {
        if (item.endDate == null) {
          const temp = mont(item.startDate).tz("America/New_York");
          const start = moment(temp._d);
          const tempnow = mont().tz("America/New_York");
          const now = moment(tempnow._d);
          const dif = now.diff(start, "minute");
          let h = Math.trunc(dif / 60);
          h = h < 10 ? "0" + h : h;
          let m = Math.trunc(dif % 60);
          m = m < 10 ? "0" + m : m;

          return h + "h:" + m + "m";
        } else {
          return "00h:00m";
        }
      }
      let hour = Math.floor(minutes / 60);
      hour = hour < 10 ? "0" + hour : hour;
      var minute = parseInt(minutes % 60);

      minute = minute < 10 ? "0" + minute : minute;
      return hour + "h" + ":" + minute + "m";
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    calculate(min, payxh, status) {
      if (status == "CLOCKOUT") {
        return 0;
      } else {
        return (Number(min) / 60) * Number(payxh);
      }
    },

    resetFilter(value) {
      switch (value) {
        case "custom-select":
          this.filterRanges = "today";

          this.dateFrom = "";
          this.dateTo = "";
          this.filterClock();
          break;
        case "employees":
          this.filterEmployees = "";
          break;
        case "status":
          this.filterStatus = "";
          break;

        default:
          this.filterEmployees = "";
          this.filterStatus = "";
          this.filterRanges = "today";
          this.bodyreq = {
            status: "",
            employeeUuid: null,
            dates: ["", ""],
          };
          this.filterClock();
          break;
      }
    },

    async srcimg(photo) {
      const token = store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "application/pdf",
            };
      this.loadingImg = true;
      if (photo != null && photo != undefined && photo != "") {
        const url = `${apiBaseUrl}/presence/photo/${photo}`;
        try {
          const res = (
            await axios({
              method: "get",
              url: url,
              responseType: "blob",
              headers: auth,
            })
          ).data;

          const buffer = new Blob([res]);
          const file_path = URL.createObjectURL(buffer);
          this.mutImagenSrc(file_path);
          this.loadingImg = false;
        } catch (error) {
          this.loadingImg = false;
          this.mutImagenSrc("no-image");
        }
      } else {
        this.loadingImg = false;
        this.mutImagenSrc("no-image");
      }
    },
  },
  mounted() {
    this.actGetEmployees();
    this.setIntervar(this.filterRanges);
  },
});
</script>
<style lang=""></style>
