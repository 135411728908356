<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="text-center">
    <v-dialog persistent v-model="addClockdialog" width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="success">
          <v-icon>mdi-clock</v-icon> Add Time</v-btn
        >
      </template>

      <v-card>
        <v-card-title class="text-h5 white--text green lighten-2">
          Add Time
        </v-card-title>

        <v-card-text>
          <v-layout row class="mt-2">
            <v-flex xs6 class="pr-1">
              <v-autocomplete
                :items="workers"
                item-text="fullname"
                item-value="uuid"
                v-model="bodyaddclock.employeeUuid"
                outlined
                dense
                label="Employee"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs6 class="pr-1">
              <ma-date-picker v-model="bodyaddclock.day" label="Date" past />
            </v-flex>
          </v-layout>
          <v-layout row class="mt-2">
            <v-flex xs12 class="pr-1">
              <v-text-field
                label="Details"
                outlined
                v-model="bodyaddclock.details"
                dense
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row class="mt-2">
            <v-flex xs6>
              <v-menu
                ref="menustart"
                v-model="startdatemenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="bodyaddclock.dateRange.date1"
                    label="Start Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="startdatemenu"
                  ampm-in-title
                  v-model="bodyaddclock.dateRange.date1"
                  full-width
                ></v-time-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6 class="pr-1">
              <v-menu
                ref="menuend"
                v-model="enddatemenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="bodyaddclock.dateRange.date2"
                    label="End Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="enddatemenu"
                  ampm-in-title
                  v-model="bodyaddclock.dateRange.date2"
                  full-width
                ></v-time-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelAddClock"> Cancel </v-btn>
          <v-btn color="primary" text @click="addClock"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import moment from "moment";
import { mapActions, mapState } from "vuex";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
export default {
  name: "add-clockin",
  components: { MaDatePicker },
  data: () => ({
    addClockdialog: false,
    startdatemenu: false,
    enddatemenu: false,
    bodyaddclock: {
      status: "CLOKING",
      details: "",
      day: "",
      dateRange: {
        date1: "",
        date2: "",
      },
      employeeUuid: "",
    },
  }),
  computed: {
    ...mapState("crmEmployeeModule", ["workers"]),
  },
  methods: {
    ...mapActions("crmConfigurationsModule", ["actAddClockIn"]),
    addClock() {
      const body = this.bodyaddclock;
      const hd1 = body.dateRange.date1.split(":")[0];
      const md1 = body.dateRange.date1.split(":")[1];
      const hd2 = body.dateRange.date2.split(":")[0];
      const md2 = body.dateRange.date2.split(":")[1];

      const startD = moment(body.day).hour(hd1).minute(md1).toISOString();
      const endD = moment(body.day).hour(hd2).minute(md2).toISOString();

      body.dateRange = { date1: startD, date2: endD };

      delete body.day;
      this.actAddClockIn(body).then(() => {
        this.addClockdialog = false;
        this.$emit("add-success");
        this.cancelAddClock();
      });
    },
    cancelAddClock() {
      this.addClockdialog = false;
      this.startdatemenu = false;
      this.enddatemenu = false;
      this.bodyaddclock = {
        status: "CLOKING",
        details: "",
        day: "",
        dateRange: {
          date1: "",
          date2: "",
        },
        employeeUuid: "",
      };
    },
  },
};
</script>
